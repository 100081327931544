import { Injectable } from '@angular/core';
import { FirebaseStorageService, GuidUtils } from 'common';
import { AssetRepository } from './asset.repository';
import { TenantService } from '../auth/services/tenant.service';
import {
  Asset,
  AssetsFirebaseId,
  Challenge,
  Mission,
  TranslatedContent,
} from '@freddy/models';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  mergeMap,
  Observable,
  toArray,
} from 'rxjs';
import { Locale } from 'locale-enum';
import { take } from 'rxjs/operators';
import { QueryConstraint, where } from '@angular/fire/firestore';

export enum AssetType {
  INTRO = 'intro',
  OUTRO = 'outro',
  COMPANY_LOGO = 'company_logo',
}

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(
    private readonly firebaseStorageService: FirebaseStorageService,
    private readonly assetRepository: AssetRepository,
    private readonly tenantService: TenantService,
  ) {}

  async uploadAndCreateAsset(
    file: File,
    metadata?: Record<string, string>,
  ): Promise<Asset> {
    const uid = GuidUtils.generateUuid();

    const fileUpload = await this.firebaseStorageService.uploadFile(
      this.getAssetPath(uid),
      file,
      metadata,
    );

    const newAsset: Asset = {
      uid: uid,
      path: fileUpload.filePath,
      contentType: file.type,
      name: file.name,
      size: file.size,
      metadata: metadata,
    };
    this.assetRepository.create(newAsset);

    return newAsset;
  }

  async copyAssetById(assetId: string): Promise<Asset> {
    const asset = await firstValueFrom(this.assetRepository.get(assetId));
    if (!asset) {
      throw new Error(`Asset with id ${assetId} not found`);
    }
    return this.copyAsset(asset);
  }

  async copyAsset(asset: Asset): Promise<Asset> {
    const uid = GuidUtils.generateUuid();
    await this.firebaseStorageService.copyFile(
      asset.path,
      this.getAssetPath(uid),
    );
    const newAsset: Asset = {
      uid: uid,
      path: asset.path,
      contentType: asset.contentType,
      name: asset.name,
      size: asset.size,
      clonedFrom: asset.uid,
      metadata: asset.metadata,
    };
    this.assetRepository.create(newAsset);
    return newAsset;
  }

  getIntroAssets(mission: Mission | Challenge): Observable<Asset[]> {
    return this.getAssets(mission, AssetType.INTRO);
  }

  getFile(assetId: AssetsFirebaseId): Promise<File | null> {
    return firstValueFrom(
      this.assetRepository.get(assetId).pipe(
        take(1),
        mergeMap((asset) => this.firebaseStorageService.getFile(asset.path)),
      ),
    );
  }

  getAssets(
    mission: Mission | Challenge,
    type?: AssetType,
  ): Observable<Asset[]> {
    // Helper function to split array into chunks
    const chunkArray = (array: any[], size: number): any[][] => {
      return array.reduce(
        (acc, _, index) =>
          index % size ? acc : [...acc, array.slice(index, index + size)],
        [],
      );
    };

    // Split the mission.assets array into chunks of 10
    const assetChunks = chunkArray(mission.assets, 10);

    // Map each chunk to a Firestore query
    const queries = assetChunks.map((chunk) => {
      // Construct QueryConstraints for each chunk
      const queryConstraints: QueryConstraint[] = [where('uid', 'in', chunk)];

      if (type) {
        queryConstraints.push(where('metadata.type', '==', type));
      }

      // Call getCollections with the constructed QueryConstraints
      return this.assetRepository.getCollections(undefined, queryConstraints);
    });
    // Combine and process the query results
    return combineLatest(queries).pipe(
      take(1),
      mergeMap((docsArray) => docsArray.flat()),
      toArray(),
      catchError((error: any) => {
        console.log('Error getting assets: ', error);
        return [];
      }),
    );
  }

  getTranslatedAssets(assets: Asset[]): TranslatedContent<Asset[]> {
    const translatedAssets: TranslatedContent<Asset[]> = {};
    for (const asset of assets) {
      const locale = asset?.metadata?.['locale'] as Locale | undefined;
      if (locale) {
        if (Array.isArray(translatedAssets[locale])) {
          // @ts-ignore
          translatedAssets[locale].push(asset);
        } else {
          translatedAssets[locale] = [asset];
        }
      }
    }
    return translatedAssets;
  }

  private getAssetPath(uid: string): string {
    return (
      this.tenantService.getOrganizationPrefixPath() +
      AssetRepository.DOC_PATH +
      '/' +
      uid
    );
  }
}
