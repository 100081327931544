import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-organization-not-found',
  templateUrl: './organization-not-found.component.html',
  styleUrls: ['./organization-not-found.component.scss'],
  imports: [TranslateModule],
})
export class OrganizationNotFoundComponent {}
