import { Injectable } from '@angular/core';
import { Challenge, Mission } from '@freddy/models';
import { Locale } from 'locale-enum';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private readonly translate: TranslateService) {}

  getStatement(
    mission: Mission | Challenge | null | undefined,
    lang: Locale,
  ): string {
    if (!mission) return '';
    const langs = Object.keys(mission.intro);
    const fallbackLang = langs.includes(Locale.en)
      ? Locale.en
      : (langs[0] as Locale);

    return (
      mission.intro[lang]?.statement ||
      mission.intro[lang]?.title ||
      mission.intro[fallbackLang]?.statement ||
      mission.intro[fallbackLang]?.title ||
      ''
    );
  }

  getDescription(
    mission: Mission | Challenge | null | undefined,
    lang: Locale,
    type: 'intro' | 'outro' = 'intro',
  ): string {
    if (!mission) return '';
    const introOrOutro = mission[type];
    const langs = introOrOutro ? Object.keys(type) : [];
    const fallbackLang = langs.includes(Locale.en)
      ? Locale.en
      : (langs[0] as Locale);
    if (
      !mission[type]?.[lang]?.description ||
      mission[type]?.[lang]?.description === ''
    ) {
      console.warn('No description found for language: ', lang);
    }
    if (
      !mission[type]?.[fallbackLang]?.description ||
      mission[type]?.[fallbackLang]?.description === ''
    ) {
      console.warn(
        'No description found for fallback language: ',
        fallbackLang,
      );
    }
    return (
      mission[type]?.[lang]?.description ||
      mission[type]?.[fallbackLang]?.description ||
      ''
    );
  }

  getTitle(
    mission: Mission | Challenge | null | undefined,
    lang: Locale,
    type: 'intro' | 'outro' = 'intro',
  ): string {
    if (!mission) return '';
    const langs = Object.keys(mission.intro);
    const fallbackLang = langs.includes(Locale.en)
      ? Locale.en
      : (langs[0] as Locale);
    return (
      mission.intro[lang]?.title || mission[type]?.[fallbackLang]?.title || ''
    );
  }

  getAvailableLanguages(): Observable<Locale[]> {
    return of([Locale.nl_BE, Locale.fr, Locale.en, Locale.nl, Locale.de]);
  }

  getLanguageDisplayName(locale: Locale): string | undefined {
    // Use the locale for both determining the language and the display language.
    const languageDisplay = new Intl.DisplayNames([locale], {
      type: 'language',
    });

    // Extract just the language code to get its display name in its own language.
    // For locales without a region (like "en"), the entire locale is used as the language code.
    const languageCode = locale.split('-')[0];

    // Get the language name, displayed in its own language.
    const languageName = languageDisplay.of(languageCode);

    return languageName;
  }

  setLanguage(lang: string) {
    return this.translate.use(lang);
  }
}
