export abstract class FileUtils {
  public static convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert blob to base64'));
        }
      };
      reader.onerror = () => {
        reject(new Error('Failed to read blob data'));
      };
      reader.readAsDataURL(file);
    });
  }

  public static convertBase64ToBlob(
    base64String: string,
    contentType: string = 'application/octet-stream',
  ) {
    const base64Data = base64String.replace(/^data:[^,]+,/, '');
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public static convertBase64ToFile(
    base64Data: string,
    fileName: string,
  ): File {
    const b: any = FileUtils.convertBase64ToBlob(base64Data);
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return b as File;
  }

  public static convertBlobToFile(blob: Blob, fileName: string): File {
    const b: any = blob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return b as File;
  }

  public static getFileName(pathOrUi: string) {
    // Extract the file name from the image URL
    const urlParts = pathOrUi.split('/');
    return urlParts[urlParts.length - 1];
  }

  public static isBase64Image(str: string): boolean {
    // Check if the string starts with a data URI prefix for images
    const imageDataUriPrefix = /^data:image\/(png|jpe?g|gif|webp);base64,/i;

    if (!imageDataUriPrefix.test(str)) {
      return false;
    }

    // Remove the data URI prefix
    const base64String = str.replace(imageDataUriPrefix, '');

    // Check if the remaining string is valid base64
    try {
      return btoa(atob(base64String)) === base64String;
    } catch (err) {
      return false;
    }
  }
}
