import { Injectable } from '@angular/core';
import { filter, interval, Observable, takeWhile } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Game, GameStatus } from '@freddy/models';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  constructor() {}

  getRemainingTime(game$: Observable<Game | undefined>): Observable<string> {
    return game$.pipe(
      filter(Boolean),
      switchMap((game) => {
        let pausedDuration = 0;

        return interval(1000).pipe(
          map(() => {
            if (!game.plannedEndDate) {
              return '00:00'; // Immediate return if no end date
            }

            const now = new Date();
            let diff = game.plannedEndDate.getTime() - now.getTime();

            // Calculate paused duration only if the game is currently paused
            if (game.status !== GameStatus.ONGOING && game.pausedAt) {
              pausedDuration = now.getTime() - game.pausedAt?.getTime();
              diff += pausedDuration; // Adjust diff by subtracting paused duration
            }

            if (diff < 0) {
              return '00:00'; // Time's up
            }

            const minutes = Math.floor(diff / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
          }),
          takeWhile(() => game.status !== GameStatus.PAUSE, true), // Emit the last value before pausing
        );
      }),
    );
  }
}
