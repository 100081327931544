import { FileUtils } from '../utils/file.utils';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseStorageService } from './firebase-storage.service';
import { queue } from 'async';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class ImageCacheService {
  private readonly CACHE_EXPIRY = 7 * 24 * 60 * 60 * 1000; // 7 days
  private readonly blobUrls = new Map<string, string>();
  private subject = new BehaviorSubject<number>(0);

  private queue = queue<string>(async (task, callback) => {
    try {
      await this.getImage(task);
      callback();
    } catch (error) {
      console.error('Failed to process queue item:', error);
      callback();
    }
  }, 10); // 10 concurrent downloads

  constructor(
    private storageService: FirebaseStorageService,
    private platform: Platform,
  ) {
    // Clean up blob URLs when the window unloads
    if (!this.platform.is('hybrid')) {
      window.addEventListener('unload', () => this.cleanupBlobUrls());
    }
  }

  async cacheImages(firebasePaths: string[]): Promise<Observable<number>> {
    this.subject = new BehaviorSubject(0);

    const uncachedPaths = [];
    for (const path of firebasePaths) {
      if (!(await this.isCached(path))) {
        uncachedPaths.push(path);
      }
    }

    if (uncachedPaths.length === 0) {
      this.subject.next(100);
      this.subject.complete();
      return this.subject;
    }

    uncachedPaths.forEach((path) => {
      this.queue.push(path, () => {
        this.updateProgress(
          this.queue.length() + this.queue.running(),
          uncachedPaths.length,
        );
      });
    });

    this.updateProgress(
      this.queue.length() + this.queue.running(),
      uncachedPaths.length,
    );

    this.queue.drain().then(() => {
      this.subject.complete();
    });

    return this.subject;
  }

  async getImage(firebasePath: string): Promise<string> {
    const filePath = this.getFilePath(firebasePath);

    try {
      // For web platform, check blob cache first
      if (!this.platform.is('hybrid') && this.blobUrls.has(filePath)) {
        return this.blobUrls.get(filePath)!;
      }

      // Check filesystem cache
      const fileInfo = await Filesystem.stat({
        path: filePath,
        directory: Directory.Cache,
      });

      if (this.platform.is('hybrid')) {
        // Return file URI for hybrid platforms
        return Capacitor.convertFileSrc(fileInfo.uri);
      } else {
        // For web, create and cache blob URL
        const file = await Filesystem.readFile({
          path: filePath,
          directory: Directory.Cache,
        });

        return this.createAndCacheBlobUrl(filePath, file.data);
      }
    } catch (error) {
      // Download and cache the file
      return this.downloadAndCacheImage(firebasePath, filePath);
    }
  }

  private async downloadAndCacheImage(
    firebasePath: string,
    filePath: string,
  ): Promise<string> {
    try {
      const file = await this.storageService.getFile(firebasePath);

      if (!file || file.size === 0) {
        throw new Error('Invalid file');
      }

      if (this.platform.is('hybrid')) {
        // For hybrid platforms, write the file directly
        await Filesystem.writeFile({
          path: filePath,
          data: file.slice(),
          directory: Directory.Cache,
        });

        const fileInfo = await Filesystem.stat({
          path: filePath,
          directory: Directory.Cache,
        });

        return fileInfo.uri;
      } else {
        // For web platform, create and cache blob URL
        const blobUrl = URL.createObjectURL(file);
        this.blobUrls.set(filePath, blobUrl);

        // Cache the file in the background
        this.cacheFileInBackground(filePath, file);

        return blobUrl;
      }
    } catch (error) {
      console.error('Failed to download and cache image:', error);
      // Fallback to direct URL
      return this.storageService.getDownloadUrl(firebasePath);
    }
  }

  private async cacheFileInBackground(
    filePath: string,
    file: Blob,
  ): Promise<void> {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const base64Data = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '',
        ),
      );
      await Filesystem.writeFile({
        path: filePath,
        data: base64Data,
        directory: Directory.Cache,
      });
    } catch (error) {
      console.error('Failed to cache file in background:', error);
    }
  }

  private createAndCacheBlobUrl(filePath: string, data: string | Blob): string {
    const blob =
      data instanceof Blob
        ? data
        : FileUtils.convertBase64ToBlob(data, 'image/jpeg');
    const blobUrl = URL.createObjectURL(blob);
    this.blobUrls.set(filePath, blobUrl);

    return blobUrl;
  }

  private cleanupBlobUrls(): void {
    this.blobUrls.forEach((url) => URL.revokeObjectURL(url));
    this.blobUrls.clear();
  }

  private isCacheExpired(mtime: string | undefined): boolean {
    if (!mtime) return true;
    const modifiedTime = new Date(mtime).getTime();
    return Date.now() - modifiedTime > this.CACHE_EXPIRY;
  }

  private async isCached(firebasePath: string): Promise<boolean> {
    const filePath = this.getFilePath(firebasePath);
    try {
      await Filesystem.stat({
        path: filePath,
        directory: Directory.Cache,
      });
      return true;
    } catch {
      return false;
    }
  }

  private getFilePath(firebasePath: string): string {
    return `${FileUtils.getFileName(firebasePath)}`;
  }

  private updateProgress(inProgress: number, total: number): void {
    this.subject.next(100 - Math.round((inProgress / total) * 100));
  }
}
