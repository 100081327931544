import { Injectable } from '@angular/core';
import Typesense, { Client } from 'typesense';
import {
  SearchParams,
  SearchResponse,
} from 'typesense/lib/Typesense/Documents';
import { environment } from '../../../environments/environment';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectUtil } from '../utils/object.utils';
import { TenantService } from '../auth/services/tenant.service';

@Injectable()
export class SearchService {
  constructor(private readonly tenantService: TenantService) {
    this.client = new Typesense.Client({
      nodes: [
        {
          host: environment.typesense.host, // where xxx is the ClusterID of your Typesense Cloud cluster
          port: environment.typesense.port,
          protocol: environment.typesense.protocol,
        },
      ],
      apiKey: environment.typesense.apiKey,
      connectionTimeoutSeconds: 2,
    });
  }

  private readonly client: Client;

  search<T extends Record<string, any>>(
    collection: string,
    searchParameters: SearchParams,
  ): Observable<SearchResponse<T>> {
    return from(
      this.client
        .collections<T>(collection)
        .documents()
        .search({
          ...searchParameters,
          filter_by: `organization:=${this.tenantService.currentOrganizationSlug}`,
        }),
    ).pipe(
      map((searchResponse) => ({
        ...searchResponse,
        hits: this.convertDotNotationToObject(searchResponse.hits),
      })),
    );
  }

  private convertDotNotationToObject<T>(items?: any[]): any[] | undefined {
    return items?.map((item) => ({
      ...item,
      document: ObjectUtil.dotNotationToObject(item.document),
    }));
  }
}
