import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizationRepository } from '../repository/organization.repository';
import { MissingIdError } from '../../../shared/errors/missing-id.error';
import { Organization } from '@freddy/models';

@Injectable({
  providedIn: 'root',
})
export class OrganizationResolver {
  constructor(
    private readonly organizationRepository: OrganizationRepository,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<Organization | undefined>
    | Promise<Organization>
    | Organization {
    const uid = route.paramMap.get('id');
    if (uid) {
      return this.organizationRepository.get(uid);
    }
    throw new MissingIdError();
  }
}
