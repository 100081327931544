// Define a recursive type to handle nested objects
export type DeepMerge<T, U> = U extends object
  ? T extends object
    ? {
        [K in Extract<keyof T, keyof U>]: DeepMerge<T[K], U[K]>;
      } & {
        [K in Exclude<keyof T, keyof U>]: T[K];
      }
    : U
  : U;

export abstract class ObjectUtils {
  // Define a function that performs a deep merge
  public static deepMerge<T, U>(obj1: T, obj2: U): DeepMerge<T, U> {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      // If at least one of them is not an object, use obj2 to override obj1
      return obj2 as DeepMerge<T, U>;
    }

    const result = { ...obj1 } as DeepMerge<T, U>;

    for (const key in obj2) {
      if (Object.hasOwn(obj2, key)) {
        // Recursively merge nested objects
        // @ts-ignore
        result[key] = ObjectUtils.deepMerge(obj1[key], obj2[key]);
      }
    }
    return result;
  }

  arraysContainSameValues(arr1: unknown[], arr2: unknown[]) {
    // Check if both arrays are defined
    if (!arr1 || !arr2) return false;

    // Check if both parameters are indeed arrays
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;

    // Quick length check can save time
    if (arr1.length !== arr2.length) return false;

    // Use a Set for constant time lookups
    const set = new Set(arr1);

    // Check every element in the second array against the set
    return arr2.every((item) => set.has(item));
  }

  public static isValidUrl(data: string) {
    try {
      new URL(data);
      return true;
    } catch (err) {
      return false;
    }
  }
}
