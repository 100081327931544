import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObjectUtil } from '../../../../core/utils/object.utils';
import { ColumnDefs, ColumnType } from '../collection-list.component';

@Component({
  selector: 'app-collection-list-item',
  templateUrl: './collection-list-item.component.html',
  styleUrls: ['./collection-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionListItemComponent {
  @Input()
  column!: ColumnDefs<any>;

  @Input()
  item!: any;
  getColumnValue(column: ColumnDefs<any>, item: any) {
    const value = column.valueGetter
      ? column.valueGetter(item)
      : ObjectUtil.get(item, column.field ?? '');
    return column.valueFormatter ? column.valueFormatter(value) : value;
  }

  executeAction($event: any, item: any, column: ColumnDefs<any>) {
    if (column.action) {
      column.action($event, item);
    }
  }

  protected readonly ColumnType = ColumnType;
}
