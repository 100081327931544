import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, switchMap } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[copy]',
})
export class CopyDirective implements OnInit {
  @Input() copy: string | boolean | undefined;

  constructor(
    private host: ElementRef<HTMLElement>,
    private zone: NgZone,
    private toast: HotToastService,
  ) {}

  ngOnInit() {
    if (!this.copy) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      fromEvent(this.host.nativeElement, 'click')
        .pipe(
          switchMap(() => {
            const toCopyValue =
              typeof this.copy == 'string'
                ? this.copy
                : this.host.nativeElement.innerText;
            return navigator.clipboard.writeText(toCopyValue);
          }),
          untilDestroyed(this),
        )
        .subscribe(() => this.toast.success('Copied to clipboard!'));
    });
  }
}
