<button
  [disabled]="disabled || loading"
  class="inline-flex items-center rounded-md border transition ease-in-out duration-150 border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 disabled:opacity-50 disabled:hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
  type="button"
>
  @if (loading) {
    <svg
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      ></path>
    </svg>
  }

  @if (loading) {
    Processing...
  } @else {
    <ng-content></ng-content>
  }
</button>
