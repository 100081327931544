import { v4 as uuidv4 } from 'uuid';
import ShortUniqueId from 'short-unique-id';

export abstract class GuidUtils {
  static generateUuid(): string {
    return uuidv4();
  }

  static generateShortCode(): string {
    const uid = new ShortUniqueId({
      dictionary: 'alphanum_upper',
      length: 6,
    });
    return uid();
  }
}
