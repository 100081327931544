import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, finalize, Observable, of, switchMap } from 'rxjs';
import { getAuth } from 'firebase/auth';
import { TenantService } from '../services/tenant.service';
import { Store } from '@ngxs/store';
import { OrganizationsConfigFetched } from '../actions/organizations.action';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AppLoaderAction } from '../../layout/actions/layout.actions';

@Injectable({
  providedIn: 'root',
})
export class OrganizationConfigResolver {
  constructor(
    private readonly tenantService: TenantService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const organizationId = route.paramMap.get('organizationId');
    if (organizationId) {
      this.tenantService.currentOrganizationSlug = organizationId;
      this.store.dispatch(new AppLoaderAction(true));
      return this.tenantService
        .getOrganizationConfig(organizationId.toLowerCase())
        .pipe(
          map((config) => {
            if (config) {
              this.store.dispatch(new OrganizationsConfigFetched(config));
              getAuth().tenantId = config.tenantId ?? null;
              return true;
            } else {
              if (organizationId !== 'public') {
                this.router.navigate(['/404']);
                localStorage.removeItem(TenantService.TENANT_STORAGE_KEY);
                this.authService.logout();
              }
              return false; // Block access if config is undefined
            }
          }),
          catchError((error) => {
            console.error('Failed to fetch organization config', error);
            this.router.navigate(['/404']);
            return of(false); // Optionally block access on error
          }),
          finalize(() => {
            this.store.dispatch(new AppLoaderAction(false));
          }),
        );
    }
    return of(false); // Block access if organizationId is falsy
  }
}
