import mapboxgl from 'mapbox-gl';

export class SatelliteToggleControl implements mapboxgl.IControl {
  private map?: mapboxgl.Map;
  private container: HTMLDivElement;
  private button: HTMLButtonElement;

  constructor() {
    this.container = document.createElement('div');
    this.button = document.createElement('button');
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    this.button.className = 'satellite-toggle';
    this.button.type = 'button';
    this.button.innerHTML =
      '<span class="mapboxgl-ctrl-icon" aria-hidden="true" title="Toggle Satellite View"></span>';

    this.button.onclick = this.toggleSatellite.bind(this);

    this.container.appendChild(this.button);
    return this.container;
  }

  onRemove(): void {
    this.container.parentNode?.removeChild(this.container);
    this.map = undefined;
  }

  private toggleSatellite(): void {
    if (!this.map) return;

    const style = this.map.getStyle();
    if (style?.sprite?.includes('streets')) {
      this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
      this.button.classList.add('satellite-active');
    } else {
      this.map.setStyle('mapbox://styles/mapbox/streets-v12');
      this.button.classList.remove('satellite-active');
    }
  }
}
