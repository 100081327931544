// marker-host.component.ts
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Type,
} from '@angular/core';
import { MapMarker } from '../map/map.component';

@Component({
  selector: 'app-marker-host',
  template:
    '@if (marker) {<ng-container *ngComponentOutlet="component; ndcDynamicInputs: inputs"></ng-container>}',
})
export class MarkerHostComponent implements OnChanges {
  @Input() marker: MapMarker | undefined;
  myInjector: any;

  inputs = {};

  get component() {
    return this.marker?.component ?? null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['marker'] && this.marker?.component) {
      this.inputs = this.marker.params ?? {};
    }
  }
}
