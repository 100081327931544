@switch (column.type) {
  @case (ColumnType.DATE) {
    <time [attr.datetime]="getColumnValue(column, item) | date: 'yyyy-MM-dd'">
      {{ getColumnValue(column, item) | date: 'mediumDate' }}
    </time>
  }
  @case (ColumnType.ACTION) {
    <a
      class="cursor-pointer group inline-flex space-x-2 truncate text-sm"
      (click)="executeAction($event, item, column)"
    >
      <p
        class="text-indigo-600 hover:text-indigo-900 truncate group-hover:text-gray-900"
      >
        {{ getColumnValue(column, item) }}
      </p>
    </a>
  }
  @case (ColumnType.CUSTOM) {
    @if (column.component && column.injector) {
      <ng-container
        *ngComponentOutlet="column.component; injector: column.injector(item)"
      ></ng-container>
    }
  }
  @case (ColumnType.COLOR) {
    <span
      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
      [ngStyle]="{ 'background-color': getColumnValue(column, item) }"
    >
      {{ getColumnValue(column, item) }}
    </span>
  }
  @case (ColumnType.STRING) {
    <p
      [copy]="column.addCopyToClipboard"
      class="text-gray-500 group-hover:text-gray-900 truncate"
    >
      {{ getColumnValue(column, item) }}
    </p>
  }
  @case (ColumnType.BUTTON) {
    @if (column.disabled && column.disabled(item)) {
      <app-secondary-button [disabled]="true">
        {{ getColumnValue(column, item) }}
      </app-secondary-button>
    } @else {
      <app-primary-button (click)="executeAction($event, item, column)">
        {{ getColumnValue(column, item) }}
      </app-primary-button>
    }
  }
  @case (ColumnType.IMAGE) {
    <div class="h-10 w-10 flex-shrink-0">
      <img
        class="h-10 w-10 rounded-full"
        [src]="getColumnValue(column, item)"
        alt=""
      />
    </div>
  }
}
