import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// @ts-ignore
window['FIREBASE_APPCHECK_DEBUG_TOKEN'] = false;

import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';
import { browserTracingIntegration } from '@sentry/angular';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],
  release: environment.version,
  environment: environment.name,
  // Performance Monitoring
  tracesSampleRate: environment.sentry.tracesSampleRate, //  Capture 100% of the transactions
  // Session Replay

  replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
