import { NgModule } from '@angular/core';
import { UppercaseDirective } from '../directives/uppercase.directive';
import { FirebaseImageDirective } from '../directives/firebase-image.directive';
import { SmileyRatingInputComponent } from '../components/smiley-rating-input/smiley-rating-input.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AgoFormatterPipe } from '../directives/ago.formatter';
import { TruncatePipe } from '../directives/truncate.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [FormsModule, CommonModule, TranslateModule],
  declarations: [
    UppercaseDirective,
    AgoFormatterPipe,
    FirebaseImageDirective,
    SmileyRatingInputComponent,
    TruncatePipe,
  ],
  exports: [
    UppercaseDirective,
    AgoFormatterPipe,
    FirebaseImageDirective,
    SmileyRatingInputComponent,
    TruncatePipe,
  ],
})
export class SharedCommonModule {}
