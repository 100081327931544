export abstract class StringUtils {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  /* istanbul ignore next */

  static formatDotNotation(dotNotationString?: string) {
    return dotNotationString
      ?.split('.')
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  }

  /**
   * Gets the first 'count' characters of the specified String object.
   * If the String object does not contain enough characters, the String object is returned
   *
   * @static
   * @param {string} value string object
   * @param {number} count number of chars to keep from the position zero of the String object
   * @returns {string} first 'count' of the String object
   * @memberof StringUtil
   */
  static getTruncatedWithCount(value: string, count: number): string {
    return value.length > count ? value.substring(0, count) : value;
  }

  /**
   * Return true if {object} is a string
   *
   * @static
   * @returns {boolean}
   * @memberof StringUtil
   * @param object
   */
  static isString(object: any): boolean {
    return typeof object === 'string';
  }

  /**
   * Return true if {object} is a string or buffer
   *
   * @static
   * @returns {boolean}
   * @memberof StringUtil
   * @param object
   */
  static isStringOrBuffer(object: any): boolean {
    if (Buffer.isBuffer(object)) {
      return true;
    }
    if (typeof object === 'string') {
      return true;
    }
    return false;
  }

  static slugify(str: string): string {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing spaces
    str = str.toLowerCase(); // convert to lowercase
    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
  }
}
