import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  FetchOrganizationAction,
  LogoutAction,
  SetUserDataAction,
  SignInWithGoogleAction,
} from '../actions/authentication.action';
import { AuthService } from '../services/auth.service';
import { TenantService } from '../services/tenant.service';
import { Organization, OrganizationConfig, User } from '@freddy/models';
import { OrganizationsConfigFetched } from '../actions/organizations.action';
import { tap } from 'rxjs/operators';
import { OrganizationRepository } from '../../../features/organization/repository/organization.repository';

export const AUTHENTICATION_STATE_TOKEN =
  new StateToken<AuthenticationStateModel>('authentication');

export interface AuthenticationStateModel {
  user: User | null;
  config?: OrganizationConfig;
  currentOrganization?: Organization;
}

@State<AuthenticationStateModel>({
  name: AUTHENTICATION_STATE_TOKEN,
  defaults: {
    user: null,
  },
})
@Injectable()
export class AuthenticationState {
  constructor(
    private readonly authService: AuthService,
    private readonly tenantService: TenantService,
    private readonly organizationRepository: OrganizationRepository,
    private readonly store: Store,
  ) {}

  @Selector()
  static googleProviderEnabled(state: AuthenticationStateModel): boolean {
    return state.config?.google || false;
  }

  @Selector([AuthenticationState])
  static currentOrganization(
    state: AuthenticationStateModel,
  ): Organization | undefined {
    return state.currentOrganization;
  }

  @Selector()
  static isPartner(state: AuthenticationStateModel): boolean {
    return !!state.user?.partner;
  }

  @Selector()
  static user(state: AuthenticationStateModel): User | null {
    return state.user;
  }

  @Action(OrganizationsConfigFetched)
  organizationsConfigFetched(
    ctx: StateContext<AuthenticationStateModel>,
    action: OrganizationsConfigFetched,
  ) {
    ctx.patchState({
      config: action.config,
    });
  }

  @Action(LogoutAction)
  async Logout(ctx: StateContext<AuthenticationStateModel>) {
    await this.authService.signOut();
    ctx.setState({
      user: null,
    });
  }

  @Action(SetUserDataAction)
  async SetUserDataAction(
    ctx: StateContext<AuthenticationStateModel>,
    action: SetUserDataAction,
  ) {
    const userOrganization = this.tenantService.currentOrganizationSlug;
    if (userOrganization) {
      this.store.dispatch(new FetchOrganizationAction(userOrganization));
    }
    ctx.patchState({
      user: action.user,
    });
  }

  @Action(FetchOrganizationAction)
  fetchOrganizationAction(
    ctx: StateContext<AuthenticationStateModel>,
    action: FetchOrganizationAction,
  ) {
    const state = ctx.getState();
    return this.organizationRepository
      .getOrganizationBySlug(action.organizationSlug)
      .pipe(
        tap((organization) => {
          ctx.setState({
            ...state,
            currentOrganization: organization,
          });
        }),
      );
  }

  @Action(SignInWithGoogleAction)
  asignInWithGoogleAction(
    ctx: StateContext<AuthenticationStateModel>,
    action: SignInWithGoogleAction,
  ) {
    return this.authService.signInWithGoogle();
  }
}
