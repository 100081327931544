@if (center) {
  <mgl-map
    [style]="'mapbox://styles/mapbox/streets-v12'"
    [zoom]="[15]"
    [panToOptions]=""
    (mapCreate)="map = $event"
    (mapLoad)="mapLoaded()"
    [scrollZoom]="false"
    [center]="center"
  >
    @if (legends; as legends) {
      <mgl-control position="top-left">
        <div class="bg-gray-100 p-2 flex flex-col items-center">
          @for (legend of legends; track legend) {
            <svg-icon
              [name]="legend.icon"
              [svgClass]="legend.markerClass"
            ></svg-icon>
            {{ legend.label }}
            <span class="mt-2"></span>
          }
        </div>
      </mgl-control>
    }
    @for (circlePoints of circles; track circlePoints; let i = $index) {
      <mgl-layer
        [id]="guidUtil.generateUuid()"
        type="fill"
        [source]="{
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: createCircle(
                circlePoints.center,
                circlePoints.radiusInMeters
              ).geometry.coordinates
            }
          }
        }"
        [paint]="{
          'fill-color': circlePoints.fill ? circlePoints.color : 'transparent',
          'fill-opacity': circlePoints.opacity,
          'fill-outline-color': circlePoints.color
        }"
      ></mgl-layer>
    }
    @for (line of lines; track line) {
      <mgl-layer
        [id]="guidUtil.generateUuid()"
        type="line"
        [source]="{
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: getCoordinates(line.coordinates)
            }
          }
        }"
        [layout]="{
          'line-join': 'round',
          'line-cap': 'round'
        }"
        [paint]="{
          'line-color': line.color,
          'line-width': line.width
        }"
      ></mgl-layer>
    }
    @for (marker of markers; track marker) {
      <mgl-marker
        [draggable]="marker.draggable"
        (markerDragEnd)="onMarkerDragEnd(marker, $event)"
        [feature]="{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: getCoordinate(marker.coordinate)
          }
        }"
      >
        <div
          (click)="onMarkerClick($event, marker)"
          [ngClass]="{
            'cursor-pointer': marker.click
          }"
        >
          @if (marker.component) {
            <app-marker-host [marker]="marker"></app-marker-host>
          } @else {
            <svg-icon
              class="center-marker"
              [name]="marker.icon"
              [svgClass]="marker.markerClass"
            ></svg-icon>
          }
          <ng-template #icon>
            <svg-icon
              [name]="marker.icon"
              [svgClass]="marker.markerClass"
            ></svg-icon>
          </ng-template>
          @if (marker.title) {
            <p class="marker-title m-2" [className]="marker.titleClass">
              {{ marker.title }}
            </p>
          }
          <!--
    <div class="pulse"></div>
    -->
        </div>
      </mgl-marker>
    }
  </mgl-map>
} @else {
  No center
}
