import { BaseFirebaseModel } from './base.firebase.model';
import { Scenario } from './scenario/scenario';
import { AssetsFirebaseId } from './mission';
import { Locale } from 'locale-enum';

export enum GameStatus {
  ONGOING = 'ONGOING',
  PLANNED = 'PLANNED',
  STOPPED = 'STOPPED',
  DONE = 'DONE',
  PAUSE = 'PAUSE',
  ARCHIVED = 'ARCHIVED',
}

export interface Game extends BaseFirebaseModel {
  name: string;
  nbPlayers: number;
  duration: number;
  nbChallenges: number;
  challengesEnabled: boolean;
  showRanking: boolean;
  emergencyNumber: string;
  scheduledGameDate: Date | null;
  pausedAt?: Date | null;
  remarks: string;
  scenario: Scenario;
  code: string;
  status: GameStatus;
  plannedEndDate?: Date | null;
  companyLogo?: AssetsFirebaseId | null;
  organization?: string; // We need this to restrict search per organization because the documents are indexed in the same collection.
  emailForContent: string;
  communicationLanguage: 'fr' | 'nl' | 'en';
  isDiyMode: boolean;
  isTestMode: boolean;
  languagesAvailable?: Locale[];
}
