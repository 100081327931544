import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { LayoutState } from './core/layout/store/layout.store';
import { Observable } from 'rxjs';
import { UpdateService } from 'common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(private readonly updateService: UpdateService) {}

  @Select(LayoutState.appLoading)
  loading$!: Observable<boolean>;
}
