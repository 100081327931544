import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, filter, first, interval } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    appRef: ApplicationRef,
    swUpdate: SwUpdate,
    private readonly toast: HotToastService,
  ) {
    swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe((evt) => {
        this.toast.info('Update available, reloading...');
        setTimeout(() => {
          document.location.reload();
        }, 1000);
      });

    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable));
    const everyOneHours$ = interval(60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyOneHours$);

    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        if (!swUpdate.isEnabled) return;
        const updateFound = await swUpdate.checkForUpdate();
        console.log(
          updateFound
            ? 'A new version is available.'
            : 'Already on the latest version.',
        );
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }
}
