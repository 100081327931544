import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';

export const getImageProvider = (storageBucket: string) => ({
  provide: IMAGE_LOADER,
  useValue: (config: ImageLoaderConfig) => {
    if (
      config.src.includes('assets/') &&
      !config.src.includes('organizations/')
    ) {
      return config.src;
    }
    const getSuffixImageSize = (config: ImageLoaderConfig) => {
      if (!config.width) {
        return '1200x1200';
      } else if (config.width <= 150) {
        return `150x150`;
      } else if (config.width <= 400) {
        return `400x400`;
      } else if (config.width <= 600) {
        return `600x600`;
      } else if (config.width <= 1280) {
        return `1280x1200`;
      }
      return '1200x1200';
    };
    const baseUrl = `https://storage.googleapis.com/${storageBucket}/`;
    const pathParts = config.src.split('/');
    const thumb = `thumbs/${
      pathParts[pathParts.length - 1]
    }_${getSuffixImageSize(config)}`;
    return `${baseUrl}${pathParts.slice(0, -1).join('/')}/${thumb}`;
  },
});
