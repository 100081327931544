export class SetTopActionButton {
  static readonly type = '[Layout] Set top action button';

  constructor(
    public readonly action: any,
    public readonly label: string,
  ) {}
}

export class AppLoaderAction {
  static readonly type = '[Layout] Show full page loader';

  constructor(public readonly isLoading: boolean) {}
}
