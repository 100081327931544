import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Type,
} from '@angular/core';
import { NestedKeyOf, PaginatedResult } from '@freddy/common';
import { StringUtils } from '../../../core/utils/string.utils';
import { ObjectUtil } from '../../../core/utils/object.utils';
import { WithUid } from '../../../../../../common/src/lib';

export enum ColumnType {
  STRING = 'string',
  DATE = 'date',
  ACTION = 'action',
  COLOR = 'color',
  CUSTOM = 'custom',
  IMAGE = 'image',
  BUTTON = 'button',
}

//@ts-ignore
export interface ColumnDefs<TData = any> {
  headerName?: string;
  field?: NestedKeyOf<TData>;
  //@ts-ignore
  action?: (event: any, item: TData) => void;
  type: ColumnType;
  addCopyToClipboard?: boolean;
  //@ts-ignore
  valueGetter?: (param: TData) => any;
  valueFormatter?: (param: TData) => string;
  component?: Type<unknown>;
  disabled?: (item: TData) => boolean;
  injector?: (item: TData) => Injector;
}

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionListComponent<T extends WithUid<unknown>>
  implements OnChanges
{
  @Input()
  title: string | undefined;

  @Input()
  prevButtonLabel: string | undefined;

  @Input()
  disablePagination: boolean | undefined = false;

  @Input()
  nextButtonLabel: string | undefined;

  @Input()
  selectedItemsLabel: string | undefined;

  @Input()
  selectable: boolean | undefined = false;

  @Input()
  columnDefs: ColumnDefs[] = [];

  @Input()
  loading: boolean | null = false;

  @Input()
  collection: PaginatedResult<T> | undefined | null;

  @Output()
  prevEvent = new EventEmitter();

  @Output()
  nextEvent = new EventEmitter();

  @Output() selectionChanged = new EventEmitter<T[]>();

  selectedItems = new Map<string, T>();
  selectAll = false;

  toggleAll() {
    if (this.selectAll) {
      this.collection?.items.forEach((item) =>
        this.selectedItems.set(item.uid, item),
      );
    } else {
      this.collection?.items.forEach((item) =>
        this.selectedItems.delete(item.uid),
      );
    }
    this.emitSelectedItems();
  }

  toggleItem(item: T) {
    if (this.selectedItems.has(item.uid)) {
      this.selectedItems.delete(item.uid);
    } else {
      this.selectedItems.set(item.uid, item);
    }
    this.checkIfAllSelected();
    this.emitSelectedItems();
  }

  checkIfAllSelected() {
    this.selectAll =
      this.collection?.items.every((item) =>
        this.selectedItems.has(item.uid),
      ) ?? false;
    this.emitSelectedItems();
  }

  emitSelectedItems() {
    this.selectionChanged.emit(Array.from(this.selectedItems.values()));
  }

  prev($event: unknown) {
    this.prevEvent.emit($event);
  }

  next($event: unknown) {
    this.nextEvent.emit($event);
  }

  //@ts-ignore
  getColumnName(column: ColumnDefs<any>): string | undefined {
    return column.headerName ?? StringUtils.formatDotNotation(column.field);
  }

  //@ts-ignore
  getColumnValue(column: ColumnDefs<any>, item: any) {
    const value = column.valueGetter
      ? column.valueGetter(item)
      : ObjectUtil.get(item, column.field ?? '');
    // @ts-ignore
    return column.valueFormatter ? column.valueFormatter(value) : value;
  }

  //@ts-ignore
  executeAction($event: any, item: any, column: ColumnDefs<any>) {
    if (column.action) {
      column.action($event, item);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collection) {
      this.checkIfAllSelected();
    }
  }
}
