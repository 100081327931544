import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Command } from '@freddy/models';
import {
  CommonRepositoryAbstract,
  PathParams,
} from './common-repository.abstract';
import { doc, Firestore, updateDoc } from 'firebase/firestore';
import {
  collection,
  collectionData,
  query,
  where,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export abstract class CommandRepositoryBase extends CommonRepositoryAbstract<Command> {
  protected constructor(firestore: Firestore) {
    super(firestore);
  }

  sendCommand(command: Command, pathParams?: PathParams): Observable<void> {
    const type: string = Object.getPrototypeOf(command.action).constructor.type;
    const actionToSerialize = {
      type,
      ...command.action,
    };
    return this.add(
      {
        ...command,
        action: actionToSerialize,
      },
      pathParams,
    );
  }

  listenForCommands(teamUid: string): Observable<Command[]> {
    const path = this.generateDocPath();
    const collectionRef = collection(this.firestore, path);
    const queryConstraints = [
      where('recipientTeamUid', '==', teamUid),
      where('executed', '==', false),
    ];
    const q = query(collectionRef, ...queryConstraints);
    return collectionData(q, { idField: 'uid' }) as Observable<Command[]>;
  }

  public markCommandAsExecuted(commandId: string): Observable<void> {
    const path = `${this.generateDocPath()}/${commandId}`;
    const docRef = doc(this.firestore, path);
    return from(updateDoc(docRef, { executed: true }));
  }
}
