import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { OrganizationRepository } from '../../../features/organization/repository/organization.repository';
import { from, Observable } from 'rxjs';
import { OrganizationConfig } from '@freddy/models';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  public static TENANT_STORAGE_KEY = 'tenantId';
  public static FAVORITES_TENANT_STORAGE_KEY = 'favoriteTenants';

  constructor(public firestore: Firestore) {
    const currentTenantId = localStorage.getItem(
      TenantService.TENANT_STORAGE_KEY,
    );
    if (currentTenantId) {
      this.currentOrganizationSlug = currentTenantId;
    }
  }

  private _currentOrganizationSlug = 'public';

  get currentOrganizationSlug(): string {
    return this._currentOrganizationSlug;
  }

  set currentOrganizationSlug(value: string) {
    this._currentOrganizationSlug = value;
  }

  getOrganizationConfig(
    organizationSlug: string,
  ): Observable<OrganizationConfig | undefined> {
    const docPath = `${OrganizationRepository.DOC_PATH}/${organizationSlug}/public/config`;
    const docRef = doc(this.firestore, docPath);

    localStorage.setItem(TenantService.TENANT_STORAGE_KEY, organizationSlug);
    return from(getDoc(docRef)).pipe(
      map((docSnapshot) =>
        docSnapshot.exists()
          ? (docSnapshot.data() as OrganizationConfig)
          : undefined,
      ),
      tap((result) => {
        if (result) this.addFavoriteOrganization(organizationSlug);
        else {
          this.removeFavoriteOrganization(organizationSlug);
        }
      }),
    );
  }

  getFavoriteOrganizations(): string[] {
    const favoriteTenants = localStorage.getItem(
      TenantService.FAVORITES_TENANT_STORAGE_KEY,
    );
    return favoriteTenants ? JSON.parse(favoriteTenants) : [];
  }

  removeFavoriteOrganization(organizationSlug: string): void {
    const favoriteTenants = localStorage.getItem(
      TenantService.FAVORITES_TENANT_STORAGE_KEY,
    );
    if (favoriteTenants) {
      const favoriteTenantsArray = JSON.parse(favoriteTenants);
      const index = favoriteTenantsArray.indexOf(organizationSlug);
      if (index > -1) {
        favoriteTenantsArray.splice(index, 1);
        localStorage.setItem(
          TenantService.FAVORITES_TENANT_STORAGE_KEY,
          JSON.stringify(favoriteTenantsArray),
        );
      }
    }
  }

  addFavoriteOrganization(organizationSlug: string): void {
    const favoriteTenants = localStorage.getItem(
      TenantService.FAVORITES_TENANT_STORAGE_KEY,
    );
    if (favoriteTenants) {
      const favoriteTenantsArray = JSON.parse(favoriteTenants);
      if (favoriteTenantsArray.includes(organizationSlug)) {
        return;
      }
      favoriteTenantsArray.push(organizationSlug);
      localStorage.setItem(
        TenantService.FAVORITES_TENANT_STORAGE_KEY,
        JSON.stringify(favoriteTenantsArray),
      );
    } else {
      localStorage.setItem(
        TenantService.FAVORITES_TENANT_STORAGE_KEY,
        JSON.stringify([organizationSlug]),
      );
    }
  }

  public getOrganizationPrefixPath(): string {
    return this.currentOrganizationSlug
      ? `${OrganizationRepository.DOC_PATH}/${this.currentOrganizationSlug}/`
      : '';
  }
}
