import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AppLoaderAction, SetTopActionButton } from '../actions/layout.actions';

export const LAYOUT_STATE_TOKEN = new StateToken<LayoutStateModel>('layout');

export interface TopActionButton {
  label: string;
  visible: boolean;
  action: any;
}

export interface LayoutStateModel {
  topActionButton: TopActionButton;
  loading: boolean;
}

@State<LayoutStateModel>({
  name: LAYOUT_STATE_TOKEN,
  defaults: {
    loading: false,
    topActionButton: {
      visible: false,
      label: '',
      action: '',
    },
  },
})
@Injectable()
export class LayoutState {
  @Selector()
  static appLoading(state: LayoutStateModel): boolean {
    return state.loading;
  }

  @Action(SetTopActionButton)
  setTopActionButton(
    ctx: StateContext<LayoutStateModel>,
    action: SetTopActionButton,
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      topActionButton: {
        visible: true,
        label: action.label,
        action: action.action,
      },
    });
  }

  @Action(AppLoaderAction)
  appLoaderAction(
    ctx: StateContext<LayoutStateModel>,
    action: AppLoaderAction,
  ) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      loading: action.isLoading,
    });
  }
}
