import { Timestamp } from '@angular/fire/firestore';

export abstract class FirebaseUtils {
  public static convertDate<T>(firebaseObject: T): T {
    if (!firebaseObject) return firebaseObject;

    for (const [key, value] of Object.entries(firebaseObject)) {
      // covert items inside array
      if (value && Array.isArray(value)) {
        // @ts-ignore
        firebaseObject[key] = value.map((item) =>
          FirebaseUtils.convertDate(item),
        );
      }

      // convert inner objects
      if (value && typeof value === 'object') {
        // @ts-ignore
        firebaseObject[key] = FirebaseUtils.convertDate(value);
      }

      // convert simple properties
      // eslint-disable-next-line no-prototype-builtins
      if (value && value.hasOwnProperty('seconds')) {
        // @ts-ignore
        firebaseObject[key] = (value as Timestamp).toDate();
      }
    }
    return firebaseObject;
  }
}
