import { Injectable } from '@angular/core';
import { FormArray, FormControlStatus, FormGroup } from '@angular/forms';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

export class StepperStatus {
  constructor(
    private formGroups: FormGroup[],
    private currentPosition: number,
  ) {
    this._globalForm = new FormArray(formGroups).statusChanges.subscribe(
      (status) => {
        this.isValid$.next(status === 'VALID');
        this.status$.next(status);
      },
    );

    this._currentForm = formGroups[this.currentPosition];
    this.checkControl();
  }

  private _globalForm;
  private _currentForm: FormGroup;
  private _$next = new BehaviorSubject(false);
  private _$prev = new BehaviorSubject(false);
  private _$status = new BehaviorSubject<FormControlStatus>('INVALID');
  private _$valid = new BehaviorSubject(false);
  private _$currentValid = new BehaviorSubject(false);
  private currentFormStatusSubscription: Subscription | undefined;

  get hasNext$(): BehaviorSubject<boolean> {
    return this._$next;
  }

  get currentValid$(): BehaviorSubject<boolean> {
    return this._$currentValid;
  }

  get status$(): BehaviorSubject<FormControlStatus> {
    return this._$status;
  }

  get isValid$(): BehaviorSubject<boolean> {
    return this._$valid;
  }

  get hasPrev$(): BehaviorSubject<boolean> {
    return this._$prev;
  }

  public setPosition(index: number) {
    this.currentPosition = index;
    this.checkControl();
  }

  public next() {
    this.currentPosition = this.currentPosition + 1;
    this.checkControl();
  }

  public updateFormGroups(formGroups: FormGroup[]) {
    this._globalForm.unsubscribe();
    this.formGroups = formGroups;
    this._globalForm = new FormArray(formGroups).statusChanges.subscribe(
      (status) => {
        this.isValid$.next(status === 'VALID');
        this.status$.next(status);
      },
    );
    this.checkControl();
  }

  public prev() {
    this.currentPosition = this.currentPosition - 1;
    this.checkControl();
  }

  private checkControl() {
    this.hasPrev$.next(false);
    this.hasNext$.next(false);

    this._currentForm = this.formGroups[this.currentPosition];
    if (this.currentFormStatusSubscription) {
      this.currentFormStatusSubscription.unsubscribe();
    }
    this.currentFormStatusSubscription = this._currentForm?.statusChanges
      .pipe(
        startWith(this._currentForm.status),
        filter((status) => status === 'VALID'),
      )
      .subscribe((status) => {
        if (this.formGroups[this.currentPosition + 1]) {
          this.hasNext$.next(true);
        }
        if (this._currentForm?.status === 'VALID') {
          this._$currentValid.next(this._currentForm.valid);
        }
      });
    if (this.formGroups[this.currentPosition + 1]) {
      this.hasNext$.next(true);
    }
    if (this.formGroups[this.currentPosition - 1]) {
      this.hasPrev$.next(true);
    }
  }
}

@Injectable()
export class FormStepperService {
  getStepperStatus(formGroups: FormGroup[], currentPosition = 0) {
    return new StepperStatus(formGroups, currentPosition);
  }
}
