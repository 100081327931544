import { User } from '@freddy/models';

export class SignInWithGoogleAction {
  static readonly type = '[Authentication] Sign in with [Google]';

  constructor() {}
}

export class ForgotPasswordAction {
  static readonly type = '[Authentication] Forgot password';

  constructor(public readonly email: string) {}
}

export class CreateUserWithEmailAndPasswordAction {
  static readonly type = '[Authentication] Create User with email password';

  constructor(
    public readonly email: string,
    public readonly password: string,
  ) {}
}

export class SetUserDataAction {
  static readonly type = '[Authentication] Set User Data';

  constructor(public readonly user?: User | null) {}
}

export class LogoutAction {
  static readonly type = '[Authentication] Logout';

  constructor() {}
}

export class FetchOrganizationAction {
  static readonly type = '[Organization] Fetch Organization';

  constructor(public readonly organizationSlug: string) {}
}
