export enum MissionTypeEnum {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  AR = 'AR',
  GUIDED = 'GUIDED',
  MIN_MAX = 'MIN_MAX',
  MULTIPLE_CHOICES = 'MULTIPLE_CHOICES',
  OPEN = 'OPEN',
}
