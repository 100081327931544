import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OrganizationResolver } from './features/organization/store/organization.resolver';
import { OrganizationRepository } from './features/organization/repository/organization.repository';
import { OrganizationConfigResolver } from './core/auth/resolvers/organization-config-resolver.service';
import { DefaultGuard } from './core/auth/guard/default.guard';
import { AppComponent } from './app.component';
import { TenantRedirectGuard } from './core/auth/guard/tenantRedirect.guard';
import { OrganizationNotFoundComponent } from './core/auth/components/organization-not-found/organization-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [TenantRedirectGuard],
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [DefaultGuard],
    loadChildren: () =>
      import('./core/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: '404',
    component: OrganizationNotFoundComponent,
  },
  {
    path: ':organizationId',
    resolve: {
      organization: OrganizationConfigResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth/sign-in',
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./core/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
];

@NgModule({
  providers: [OrganizationResolver, OrganizationRepository],
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
