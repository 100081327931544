@if (collection?.items) {
  <div>
    <div class="mt-2 flex flex-col">
      <div
        class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"
      >
        <div
          class="relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
        >
          <div
            *ngIf="selectedItems.size"
            class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16"
          >
            {{ selectedItems.size }} {{ selectedItemsLabel }}
          </div>
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th
                  *ngIf="selectable"
                  scope="col"
                  class="bg-gray-50 relative w-12 px-6 sm:w-16 sm:px-8"
                >
                  <input
                    [(ngModel)]="selectAll"
                    (change)="toggleAll()"
                    type="checkbox"
                    class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                  />
                </th>
                @for (column of columnDefs; track column) {
                  <th
                    class="bg-gray-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    @if (column.type !== 'action') {
                      {{ getColumnName(column) }}
                    }
                  </th>
                }
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              @for (item of collection?.items; track item) {
                <tr class="bg-white">
                  <td
                    class="relative w-12 px-6 sm:w-16 sm:px-8"
                    *ngIf="selectable"
                  >
                    <div
                      *ngIf="selectedItems.has(item.uid)"
                      class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
                    ></div>
                    <input
                      [checked]="selectedItems.has(item.uid)"
                      (change)="toggleItem(item)"
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </td>
                  @for (
                    column of columnDefs;
                    track column;
                    let indexOfElement = $index
                  ) {
                    @if (indexOfElement === 0) {
                      <td
                        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900"
                      >
                        <div class="flex">
                          <a
                            class="cursor-pointer group inline-flex space-x-2 truncate text-sm"
                            (click)="executeAction($event, item, column)"
                          >
                            <p
                              class="truncate text-gray-500 group-hover:text-gray-900"
                            >
                              {{ getColumnValue(column, item) }}
                            </p>
                          </a>
                        </div>
                      </td>
                    }
                    @if (indexOfElement !== 0) {
                      <td
                        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-500"
                      >
                        <app-collection-list-item
                          [column]="column"
                          [item]="item"
                        ></app-collection-list-item>
                      </td>
                    }
                  }
                </tr>
              }
            </tbody>
          </table>
          <!-- Pagination -->
          <nav
            *ngIf="disablePagination === false"
            aria-label="Pagination"
            class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
          >
            <div class="flex flex-1 justify-between sm:justify-end">
              @if (prevButtonLabel) {
                <app-secondary-button
                  [loading]="loading ?? false"
                  [disabled]="(collection?.prevDisabled || loading) ?? false"
                  (click)="prev($event)"
                >
                  {{ prevButtonLabel }}
                </app-secondary-button>
              }

              @if (nextButtonLabel) {
                <app-secondary-button
                  class="ml-3"
                  [loading]="loading ?? false"
                  [disabled]="(collection?.nextDisabled || loading) ?? false"
                  (click)="next($event)"
                >
                  {{ nextButtonLabel }}
                </app-secondary-button>
              }
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
}
