import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

export function createTracingProviders(environment: any, appVersion: string) {
  const providers = [];
  providers.push(
    ...[
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: environment.sentry.showDialog,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ],
  );

  return providers;
}
